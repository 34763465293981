import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerLogin } from '../data/userController';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (e) => {
	const response = await CustomerLogin(e); // Assuming CustomerLogin returns user data
	return response;
});

// Create a slice for user management
const usersSlice = createSlice({
	name: 'users',
	initialState: {
		users: [],
		status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
		error: null,
	},
	reducers: {
		logOut: (state) => {
			state.users = null;
			state.status = 'idle';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUsers.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.status = 'succeeded';
				state.users = action.payload; // Populate the store with fetched users
			})
			.addCase(fetchUsers.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			});
	},
});
export const { logOut } = usersSlice.actions; // Export actions
export default usersSlice.reducer;
