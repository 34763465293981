/* eslint-disable react/prop-types */
import CountUp from 'react-countup';
import { FaArrowRightLong } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Popover, PopoverHandler, PopoverContent, Button } from '@material-tailwind/react';

export const PricingCard = ({
	currency = '$',
	price = 0,
	pricingDateLine = '',
	pricingDesc = '',
	pricingTitle = 'Default Title',
	pricingIcon = null,
	pricingContent = [],
	pricingUrl = '#',
	pricingBtn = 'Learn More',
	pricingShape1 = null,
	pricingShape2 = null,
	index = 0,
	srv = {},
}) => {
	const TotalPrice = () => {
		const total = pricingContent ? pricingContent.reduce((acc, srv) => acc + (srv.SubNewPrerequisite ? srv.SubIsNewPrice || 0 : 0), 0) : 0;

		return total + Number(price);
	};

	const [contentState, setContentState] = useState(() => {
		if (Array.isArray(pricingContent) && Array.isArray(pricingContent[index])) {
			return pricingContent[index].map((item) => item.SubNewPrerequisite || 0);
		}
		return [];
	});

	const handleAddOn = (i) => {
		if (!Array.isArray(pricingContent) || i < 0 || i >= pricingContent.length) {
			console.warn('Invalid content index:', i);
			return;
		}

		// Create a copy of the contentState
		const updatedContent = [...pricingContent];

		// Toggle the SubNewPrerequisite value
		updatedContent[i].SubNewPrerequisite = !updatedContent[i].SubNewPrerequisite;

		setContentState(updatedContent); // Update the state
		console.log('Updated contentState:', updatedContent);
	};

	return (
		<div
			style={{ minHeight: '500px', backgroundColor: '#fff' }}
			className="group bg-white rounded-xl relative z-10 before:absolute before:bottom-0 before:left-0 before:w-full before:h-0 before:opacity-0 before:bg-SecondaryColor-0 before:rounded-xl before:-z-10 before:transition-all before:duration-500 hover:before:h-full hover:before:opacity-100 hover:before:top-0"
		>
			<div className="absolute top-10 right-10 animate-pulse -z-10" style={{ width: '40%', height: '90px' }}>
				<img src={pricingShape1} draggable="false" />
			</div>
			<div className="absolute bottom-10 right-10 animate-zoomInOut -z-10">
				<img src={pricingShape2} draggable="false" />
			</div>
			<h4 className="font-AlbertSans font-medium text-[12px] text-PrimaryColor-0 pl-8 pr-10 py-2 bg-[#f5f8ed] rounded-r-full inline-block transition-all duration-500 mt-10">
				{pricingTitle}
			</h4>
			<div className="font-AlbertSans flex items-baseline gap-3 px-[30px] mt-6 pb-4">
				<div className="flex items-center gap-1">
					<h6 className="font-AlbertSans font-bold text-[50px] text-HeadingColor-0 transition-all duration-500 group-hover:text-white">{currency}</h6>
					<CountUp
						start={-11}
						end={TotalPrice()}
						suffix=""
						className="text-[50px] font-bold font-AlbertSans text-HeadingColor-0 transition-all duration-500 group-hover:text-white"
					/>
				</div>
				<p className="text-TextColor-0 font-AlbertSans">{pricingDateLine}</p>
			</div>
			<p className="font-AlbertSans text-TextColor-0 px-[30px]">{pricingDesc}</p>
			<ul style={{ minHeight: '120px' }}>
				{srv.prerequisites &&
					srv.prerequisites.map((pct, i) => (
						<li
							key={i}
							className="flex gap-2 items-center font-AlbertSans text-TextColor-0 transition-all duration-500 group-hover:text-white px-[30px] mb-3 mt-2"
							onClick={() => (pct.SubPrerequisite === 1 ? '' : handleAddOn(i))}
							style={{ cursor: 'pointer' }}
							title={pct.SubPrerequisite === 1 ? 'Required' : ''}
						>
							{/* {console.log('pct')} */}
							<div className="text-[10px]" style={srv[i]?.SubPrerequisite ? { color: 'green' } : { color: 'red' }}>
								{pricingIcon}
							</div>
							{pct.SubServiceTitle} ${pct.SubIsNewPrice}
						</li>
					))}
			</ul>
			<div className="px-[30px] pb-10 flex justify-center">
				<Link to={pricingUrl} state={srv}>
					<button className="primary-btn2 mt-9 group-hover:before:w-full group-hover:before:left-0">
						{pricingBtn}
						<FaArrowRightLong size={'20'} />
					</button>
				</Link>
			</div>
		</div>
	);
};

// PricingCard.propTypes = {
//   pricingContent: PropTypes.array.isRequired,
// };
PricingCard.defaultProps = {
	pricingContent: [],
};
