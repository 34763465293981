/* eslint-disable no-unused-vars */
import pricingShape1 from '/images/pricing-shape-1.png';
import pricingShape2 from '/images/pricing-shape-2.png';
import { FaCheck, FaCircle, FaStarOfLife } from 'react-icons/fa6';
import { PricingCard } from './ServicePricingCard';
import { uploadUrl } from '../../../Components/urls';
import { UseServicesCards } from '../../../Components/useServices';

const Pricing = () => {
	const ServiceData = UseServicesCards();
	const SubService = ServiceData[0]?.SubService || [];
	return (
		<section className="py-8  ">
			<div className="Container">
				<div className="flex justify-center ">
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7">
						{SubService &&
							SubService.filter((sub) => sub.SubAppearInMain === 1).map((srv, i) => {
								return (
									<div key={srv.SubServiceID}>
										<PricingCard
											currency="$"
											price={srv.SubPrice}
											pricingDateLine={'Lifetime'}
											pricingDesc={srv.SubServiceDesc}
											pricingTitle={srv.SubServiceTitle}
											pricingIcon={<FaCircle />}
											// pricingContent={srv.SubService}
											pricingContent={Array.isArray(srv.SubService) ? srv.SubService : []}
											pricingUrl="/service_details"
											pricingBtn="Choose Plan"
											pricingShape1={uploadUrl + srv.SubServicePhoto}
											// pricingShape2={srv.}
											index={i}
											srv={srv}
										/>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Pricing;
