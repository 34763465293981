import { FaArrowRightLong } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";
import shape from "/images/appointment-shape.png";
import shape2 from "/images/appointment-shape2.png";
import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Country, State, City } from "country-state-city";
import { NewCustomer } from "../../../data/userController"; 

const Register = () => {
  const [NewUser, setNewUser] = useState([]);
  const [States, setStates] = useState();
  const [Cities, setCities] = useState([]);
  const [Error, setError] = useState([]);
  const countries = Country.getAllCountries(); 

  const HandelSelectCountryCode = async (e) => {
    setNewUser({
      ...NewUser,
      CountryCode: e.phonecode,
    }); 
  };
  const HandelSelectCountry = async (e) => {
    setNewUser({
      ...NewUser,
      Country: e,
    });
    const fetchCities = async () => {
      try {
        const statess = await State.getAllStates().filter(
          (stat) => stat.countryCode === e.isoCode
        );
        setStates(statess);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    await fetchCities();
    return true;
  };

  const HandelSelectState = async (e) => {
    setNewUser({
      ...NewUser,
      State: e,
    });
    const fetchCities = async () => {
      try {
        const Citiess = await City.getAllCities().filter(
          (ct) => ct.stateCode == e.isoCode && ct.countryCode === e.countryCode
        );
        setCities(Citiess);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    await fetchCities();
    return true;
  };

  const HandelAddUser = () => {
    const ErrorTimeOut = 5000; 
    if (NewUser.Email === "" || !NewUser.Email) {
      setError("Error Email");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    if (NewUser.Password === "" || !NewUser.Password) {
      setError("Error Password");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    if (
      NewUser.ConfirmPassword !== NewUser.Password ||
      !NewUser.ConfirmPassword
    ) {
      setError("Error Password Not Mush");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    if (NewUser.FirstName === "" || !NewUser.FirstName) {
      setError("Error FirstName");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    if (NewUser.MiddleName === "" || !NewUser.MiddleName) {
      setError("Error MiddleName");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    if (NewUser.LastName === "" || !NewUser.LastName) {
      setError("Error LastName");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    if (NewUser.DateOfBirth === "" || !NewUser.DateOfBirth) {
      setError("Error Date Of Birthday");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    if (NewUser.CountryCode === "" || !NewUser.CountryCode) {
      setError("Error CountryCode");
      setTimeout(() => {
        setError("");
      }, ErrorTimeOut);
      return false;
    }
    setError("Done");
    NewCustomer(NewUser);
  };
  return (
     
          <div className="mr-2  mx-2">
            <h2 className="font-AlbertSans font-bold bold text-3xl md:text-[40px] mb-11 mx-2">
              Create New Account
            </h2>
            <div method="post" className="flex flex-col gap-7  mx-2">
              <div className="flex flex-col md:flex-row gap-7">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail*"
                  required
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.Email}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      Email: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row gap-7">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password*"
                  required
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.Password}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      Password: e.target.value,
                    })
                  }
                />
                <input
                  type="password"
                  name="cpassword"
                  id="cpassword"
                  placeholder="Confirm Password*"
                  required
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.ConfirmPassword}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      ConfirmPassword: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row gap-7">
                <input
                  type="text"
                  name="FirstName"
                  id="FirstName"
                  placeholder="First Name*"
                  required
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.FirstName}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      FirstName: e.target.value,
                    })
                  }
                />
                <input
                  type="text"
                  name="MiddleName"
                  id="name"
                  placeholder="Middle Name*"
                  required
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.MiddleName}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      MiddleName: e.target.value,
                    })
                  }
                />
                <input
                  type="text"
                  name="LastName"
                  id="name"
                  placeholder="Last Name*"
                  required
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.LastName}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      LastName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row gap-7">
              <Autocomplete
                  disablePortal 
                  options={countries}
                  getOptionLabel={ (country) =>  country.phonecode + ' ' +  country.flag  }
                  value={NewUser.CountryCode}
                  sx={{ width: 450 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Country" />
                  )}
                  className="border border-BorderColor2-0 rounded  outline-none h-[56px] w-full"
                  onChange={(e, newValue) => HandelSelectCountryCode(newValue)} 
                />
                {/* <select
                  value={NewUser.CountryCode}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      CountryCode: e.target.value,
                    })
                  }
                  className="border border-gray-300 rounded py-2 px-4 outline-none"
                >
                  {countries.map((country) => (
                    <option key={country.phonecode} value={country.phonecode}>
                      {country.flag} ({country.phonecode})
                    </option>
                  ))}
                </select> */}
                <input
                  type="mobile"
                  name="Mobile"
                  id="name"
                  placeholder="Mobile*"
                  required
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.Phone}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      Phone: e.target.value,
                    })
                  }
                />
                {/* <select
                  type="text"
                  name="gender"
                  id="gender"
                  placeholder="Gender"
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.Gender}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      Gender: e.target.value,
                    })
                  }
                > 
                  <option disabled>Select Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>*/}
                <input
                  type="date"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  placeholder="Date Of Birthday"
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.DateOfBirth}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      DateOfBirth: e.target.value,
                    })
                  }
                />
              </div>
              <div className="flex flex-col md:flex-row gap-7">
                <Autocomplete
                  disablePortal
                  //  options={countries.map((country) => country.name)}
                  options={countries}
                  getOptionLabel={(option) => option.name}
                  // sx={{ width: 300 }}
                  value={NewUser.Country}
                  renderInput={(params) => (
                    <TextField {...params} label="Country" />
                  )}
                  className="border border-BorderColor2-0 rounded  outline-none h-[56px] w-full"
                  onChange={(e, newValue) => HandelSelectCountry(newValue)}
                />
                {NewUser.Country && (
                  <Autocomplete
                    disablePortal
                    options={States ? States : []}
                    getOptionLabel={(option) => option.name}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="State" />
                    )}
                    className="border border-BorderColor2-0 rounded  outline-none h-[56px] w-full"
                    onChange={(e, newValue) => HandelSelectState(newValue)}
                  />
                )}
                {NewUser.State && (
                  <Autocomplete
                    disablePortal
                    options={Cities ? Cities : []}
                    getOptionLabel={(option) => option.name}
                    // sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="City" />
                    )}
                    className="border border-BorderColor2-0 rounded  outline-none h-[56px] w-full"
                    onChange={(e) =>
                      setNewUser({
                        ...NewUser,
                        City: e.target.value,
                      })
                    }
                  />
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-7">
                <textarea
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                  className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
                  value={NewUser.Address}
                  onChange={(e) =>
                    setNewUser({
                      ...NewUser,
                      Address: e.target.value,
                    })
                  }
                />
              </div>
              <div className="text-[#f00]">{Error}</div>
              <div className="inline-block">
                <button
                  type="submit"
                  className="primary-btn2"
                  onClick={() => HandelAddUser()}
                >
                  Register
                  <FaArrowRightLong size={"20"} />
                </button>
              </div>
            </div>
          </div> 
  );
};

export default Register;
