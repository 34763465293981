import { useDispatch } from 'react-redux';
import { useSession } from './useSession';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { decrypt, encrypt } from './encdecrypt';
import { saveInvoice } from '../redux/dataSlice';

export const UseInvoice = () => {
	const Invoice = useSelector((state) => state.data.inv || []);
	const { SessionExpired } = useSession();
	const [Invoices, setInvoices] = useState([]);
	useEffect(() => {
		const fetch = () => {
			if (SessionExpired < 0) {
				setInvoices([]);
			} else {
				try {
					const data = JSON.parse(decrypt(Invoice));
					setInvoices(data);
				} catch (error) {
					console.error('Error parsing Invoice:', error);
					setInvoices([]);
				}
			}
		};
		fetch();
		// const intervalId = setInterval(fetch, 50000);
		// return () => clearInterval(intervalId);
	}, [SessionExpired, Invoice]);
	return [Invoices, setInvoices];
};

export const HandelAddInvoice = async (sub, Invoices, setInvoices, dispatch) => {
	const data = JSON.stringify([...Invoices, sub]);
	dispatch(saveInvoice(encrypt(data)));
	setInvoices([...Invoices, sub]);
};

export const HandelRemoveInvoice = async (index, Invoices, setInvoices, dispatch) => {
	const updatedInvoices = Invoices.filter((_, i) => i !== index);
	setInvoices(updatedInvoices);
	const data = JSON.stringify(updatedInvoices);
	dispatch(saveInvoice(encrypt(data)));
};
