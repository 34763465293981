import { useEffect, useState } from "react";
import { useJWT } from "../data/useUserInfo"

export const useSession = () =>{
    const { UserInfo, loading, logout } = useJWT();
    const [SessionExpired, setSessionExpired] = useState('')  

 useEffect(() => { 
  const calculateTimeRest = () => {
    if (UserInfo && UserInfo.expirationTime) {
      return UserInfo.expirationTime - Math.floor(Date.now() / 1000);
    }
    return 0;
  }; 
  setSessionExpired(calculateTimeRest()); 
  const interval = setInterval(() => {
    setSessionExpired(calculateTimeRest());
  }, 1000); 
  return () => clearInterval(interval);
}, [UserInfo]);
return {SessionExpired}

}