import { Autocomplete, TextField } from '@mui/material';
import { useState, useRef } from 'react';
import { Country, State, City } from 'country-state-city';

export const OwnerModal = ({ NewPerson, setNewPerson, HandelAddOwner, showModal, HandelCloseOwnerModal, index }) => {
	const [errors, setErrors] = useState({
		Firstname: false,
		MiddleName: false,
		LastName: false,
		Phone: false,
		PhoneValid: false,
		Email: false,
		EmailValid: false,
		CountryCode: false,
	});

	const HandelSelectCountryCode = async (e) => {
		setNewPerson({
			...NewPerson,
			CountryCode: e.phonecode,
		});
	};
	const countries = Country.getAllCountries();
	const handleOverlayClick = (e) => {
		if (e.target.id === 'modal-overlay') {
			HandelCloseOwnerModal();
		}
	};

	const validateFields = () => {
		const newErrors = {
			Firstname: !NewPerson.Firstname,
			MiddleName: !NewPerson.MiddleName,
			LastName: !NewPerson.LastName,
			Phone: !NewPerson.Phone,
			PhoneValid: NewPerson.Phone && !/^\d{8}$/.test(NewPerson.Phone),
			Email: !NewPerson.Email,
			EmailValid: NewPerson.Email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(NewPerson.Email),
		};

		setErrors(newErrors);

		return Object.values(newErrors).some((error) => error);
	};

	const HandelAdd = () => {
		if (validateFields()) {
			return;
		}

		// Proceed with adding the owner
		HandelAddOwner(index);
	};

	const getInputClass = (field, isInvalid) => {
		return `col-span-1 text-gray-900 bg-white rounded-md border px-4 py-2 ${isInvalid ? 'border-red-500' : 'border-gray-300'}`;
	};

	return (
		<>
			{showModal && (
				<>
					{/* Modal Overlay */}
					<div id="modal-overlay" className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50" onClick={handleOverlayClick}>
						{/* Modal Content */}
						<div className="w-[500px] bg-white rounded-lg shadow-lg z-999">
							{/* Header */}
							<div className="bg-white-100 p-4 rounded-t-lg border-b">
								<h2 className="text-xl font-semibold text-gray-700">Add Owner - on order {index + 1}</h2>
							</div>

							{/* Body */}
							<div className="p-6">
								<div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 gap-4">
									{/* First Name Input */}
									<input
										type="text"
										value={NewPerson.Firstname}
										onChange={(e) =>
											setNewPerson({
												...NewPerson,
												Firstname: e.target.value,
											}) +
											setErrors({
												...errors,
												Firstname: false,
											})
										}
										placeholder="First Name"
										className={getInputClass('Firstname', errors.Firstname)}
									/>
									{errors.Firstname && <p className="text-red-500 text-sm ">First Name cannot be empty. Please enter a valid first name.</p>}
									{/* Middle Name Input */}
									<input
										type="text"
										value={NewPerson.MiddleName}
										onChange={(e) =>
											setNewPerson({
												...NewPerson,
												MiddleName: e.target.value,
											}) +
											setErrors({
												...errors,
												MiddleName: false,
											})
										}
										placeholder="Middle Name"
										className={getInputClass('MiddleName', errors.MiddleName)}
									/>
									{errors.MiddleName && <p className="text-red-500 text-sm ">Middle Name cannot be empty. Please enter a valid middle name.</p>}
									{/* Last Name Input */}
									<input
										type="text"
										value={NewPerson.LastName}
										onChange={(e) =>
											setNewPerson({
												...NewPerson,
												LastName: e.target.value,
											}) +
											setErrors({
												...errors,
												LastName: false,
											})
										}
										placeholder="Last Name"
										className={getInputClass('LastName', errors.LastName)}
									/>
									{errors.LastName && <p className="text-red-500 text-sm ">Last Name cannot be empty. Please enter a valid last name.</p>}
									{/* Phone Input */}
									<div className="flex">
										<Autocomplete
											disablePortal
											options={countries}
											getOptionLabel={(country) => country.phonecode + ' ' + country.flag}
											value={NewPerson.CountryCode}
											sx={{ width: '25%' }}
											renderInput={(params) => <TextField {...params} label="Code" />}
											className="border border-BorderColor2-0 rounded  outline-none h-[56px] w-full"
											onChange={(e, newValue) => HandelSelectCountryCode(newValue)}
										/>
										<input
											type="number"
											value={NewPerson.Phone}
											onChange={(e) =>
												setNewPerson({
													...NewPerson,
													Phone: e.target.value,
												}) +
												setErrors({
													...errors,
													Phone: false,
												})
											}
											style={{ width: '73%', marginLeft: '2%' }}
											placeholder="Phone"
											className={getInputClass('Phone', errors.Phone)}
										/>
									</div>
									{errors.Phone && <p className="text-red-500 text-sm ">Phone number cannot be empty. Please enter a valid </p>}
									{errors.PhoneValid && <p className="text-red-500 text-sm ">Invalid phone number. Please enter an 8-digit number</p>}

									{/* Email Input */}
									<input
										type="email"
										value={NewPerson.Email}
										onChange={(e) =>
											setNewPerson({
												...NewPerson,
												Email: e.target.value,
											}) +
											setErrors({
												...errors,
												Email: false,
												EmailValid: false,
											})
										}
										placeholder="Email"
										className={getInputClass('Email', errors.Email || errors.EmailValid)}
									/>
								</div>
								{(errors.EmailValid || errors.Email) && <p className="text-red-500 text-sm mt-2">Invalid email address. Please use a format like example@email.com</p>}
							</div>

							{/* Footer */}
							<div className="flex justify-end items-center p-4 bg-white-100 border-t rounded-b-lg">
								{/* Add Owner Button */}
								<button onClick={HandelAdd} className="bg-JZPrimaryColor-0 text-white px-4 py-2 rounded hover:bg-JZPrimaryColor-50 transition mx-2">
									Add
								</button>
								{/* Cancel Button */}
								<button onClick={HandelCloseOwnerModal} className="bg-JzSecondaryColor-0 text-white px-4 py-2 rounded hover:bg-red-800 transition">
									Close
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};
