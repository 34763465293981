/* eslint-disable no-unused-vars */
import { Link, NavLink } from 'react-router-dom';
import { FaBars, FaFacebookF, FaLinkedinIn, FaPhone, FaPinterestP, FaXTwitter } from 'react-icons/fa6';
import { BiChevronDown } from 'react-icons/bi';
// import Logo from "/images/Jouzour-logo.png";
import { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { IoSearch } from 'react-icons/io5';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { CiLocationOn } from 'react-icons/ci';
import { UseCompany } from '../../Components/useCompany';
import { adminUrl, uploadUrl } from '../../Components/urls';
import { MdPerson } from 'react-icons/md';
import { useJWT } from '../../data/useUserInfo';
import { useSession } from '../../Components/useSession';

const Navbar = () => {
	const { UserInfo, loading, logout } = useJWT();
	const { SessionExpired } = useSession();

	const CompanyInfos = UseCompany();
	//sticky
	useEffect(() => {
		window.addEventListener('scroll', isSticky);
		return () => {
			window.removeEventListener('scroll', isSticky);
		};
	});

	/* Method that will fix header after a specific scrollable */

	const isSticky = (e) => {
		const header = document.querySelector('.header-section');
		const scrollTop = window.scrollY;
		scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
	};

	// modal openar

	const [isOpen, setIsOpen] = useState(false);

	const toggleNavbar = () => {
		setIsOpen(!isOpen);
	};

	return (
		<nav className={`w-full transition-all duration-300 bg-transparent absolute  z-[999]`}>
			{/* top Navbar */}
			<header className="bg-transparent overflow-hidden md:block">
				<div className="Container flex items-center justify-between h-[50px]">
					<div className="flex items-center gap-10">
						<p className="font-AlbertSans text-[15px] text-white sm:flex hidden items-center gap-1">
							<CiLocationOn className="text-xl relative bottom-[2px] text-PrimaryColor-0" />
							{CompanyInfos.CompanyAddress}
						</p>
						<Link to="/" className="font-AlbertSans text-[15px] text-white md:flex items-center gap-2 hidden">
							<HiOutlineMailOpen size={'16'} className="text-PrimaryColor-0" />
							{CompanyInfos.CompanyEmail}
						</Link>
					</div>
					<div className="flex items-center gap-16">
						<div className="lg:flex items-center gap-2 hidden">
							<span className="flex items-center gap-2 text-sm text-PrimaryColor-0">
								<FaPhone />
							</span>
							<Link to="/" className="font-AlbertSans font-medium text-sm text-white">
								+961 {CompanyInfos.CompanyPhone}
							</Link>
						</div>
						<ul className="flex gap-3 items-center">
							<li>
								<Link to={CompanyInfos.CompanyFacebook} className="transition-all duration-500 text-white hover:text-PrimaryColor-0">
									<FaFacebookF />
								</Link>
							</li>
							<li>
								<Link to={CompanyInfos.CompanyXTwitter} className="transition-all duration-500 text-white hover:text-PrimaryColor-0">
									<FaXTwitter />
								</Link>
							</li>
							<li>
								<Link to={CompanyInfos.CompanyLinkedinIn} className="transition-all duration-500 text-white hover:text-PrimaryColor-0">
									<FaLinkedinIn />
								</Link>
							</li>
							<li>
								<Link to={CompanyInfos.CompanyPinterestP} className="transition-all duration-500 text-white hover:text-PrimaryColor-0">
									<FaPinterestP />
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</header>
			{/* top Navbar */}
			<header className="header-section bg-transparent border-t border-b border-BorderColor4-0" data-aos="zoom-in" data-aos-duration="1000">
				<div className="Container">
					{/* main Navbar */}
					<div className="flex flex-col lg:flex-row items-center justify-between lg:h-[100px] ">
						{/* website Logo */}
						<div className=" w-48 lg:w-52">
							<Link to="/">
								<img draggable="false" src={uploadUrl + CompanyInfos.CompanyLogo} className="hidden lg:block" alt="website_logo" />
							</Link>
						</div>
						{/* small screen size */}
						<div className="w-full lg:hidden flex justify-between h-[70px] items-center p-3">
							<div className="w-28">
								<Link to="/">
									<img draggable="false" src={uploadUrl + CompanyInfos.CompanyLogo} className="block lg:hidden " alt="constre_website_logo" />
								</Link>
							</div>
							{/* toggle bar mode. */}
							<button className="lg:hidden block focus:outline-none " onClick={toggleNavbar}>
								{/* modal open and close */}
								{isOpen ? <IoMdClose className="w-6 h-6 text-white" /> : <FaBars className="w-5 h-5 text-white" />}
							</button>
						</div>
						{/* All navLink are hear with active */}
						<div className="flex gap-2 items-center">
							<ul
								className={`${
									isOpen ? 'block' : 'hidden'
								} text-right lg:w-fit ease-in-out flex-1 lg:flex space-y-2 lg:space-y-0 space-x-0 flex flex-col lg:flex-row capitalize text-sm lg:bg-transparent py-3 lg:py-0 font-AlbertSans font-medium sm:text-base text-white transition-all duration-500
                `}
							>
								<NavLink
									to="/"
									className={`${({ isActive, isPending }) =>
										isPending
											? 'pending'
											: isActive
											? 'active'
											: ''} text-white text-left hover:text-PrimaryColor-0 lg:border-b-0 px-3 lg:px-2 xl:px-3 w-full block transition-all duration-300 group relative`}
								>
									<span className="flex items-center">Home</span>
								</NavLink>
								<NavLink
									className={`${({ isActive, isPending }) =>
										isPending
											? 'pending'
											: isActive
											? 'active'
											: ''} text-white text-left hover:text-PrimaryColor-0 lg:border-b-0 px-3 lg:px-2 xl:px-3 w-full block transition-all duration-300 group relative `}
									to="/about"
								>
									<span>About</span>
								</NavLink>
								<NavLink
									className={`${({ isActive, isPending }) =>
										isPending
											? 'pending'
											: isActive
											? 'active'
											: ''} text-white text-left hover:text-PrimaryColor-0 lg:border-b-0 px-3 lg:px-2 xl:px-3 w-full block transition-all duration-300 group relative `}
									to="#"
								>
									<span className="flex items-center">
										Service
										<BiChevronDown className="ml-1" />
									</span>
									<div className="absolute pt-5 lg:pt-8 z-20">
										<ul className="shadow-lg hidden group-hover:block rounded-sm text-white w-[240px] text-left transition-all duration-500 text-sm sm:text-base py-4 bg-HeadingColor-0 ">
											<div className="px-5 group hover:bg-PrimaryColor-0 ">
												<li className="hover:ml-3 duration-300">
													<Link to="/service" className="py-2 block">
														Service
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0 ">
												<li className="hover:ml-3 duration-300">
													<Link to="/service_details" className="py-2 block">
														Service Details
													</Link>
												</li>
											</div>
										</ul>
									</div>
								</NavLink>

								<NavLink
									className={`${({ isActive, isPending }) =>
										isPending
											? 'pending'
											: isActive
											? 'active'
											: ''} text-white text-left hover:text-PrimaryColor-0 lg:border-b-0 px-3 lg:px-2 xl:px-3 w-full block transition-all duration-300 group relative `}
									to="#"
								>
									<span className="flex items-center">
										Pages
										<BiChevronDown className="ml-1" />
									</span>
									<div className="absolute pt-5 lg:pt-8 z-20">
										<ul className="shadow-lg hidden group-hover:block rounded-sm text-white w-[240px] text-left transition-all duration-500 text-sm sm:text-base py-4 bg-HeadingColor-0 ">
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/about" className="py-2 block">
														About Us
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/service" className="py-2 block">
														Service
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/service_details" className="py-2 block">
														Service Details
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/donation_inner" className="py-2 block">
														Donations
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/team_inner" className="py-2 block">
														Team Member
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/project" className="py-2 block">
														Projects
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/project_details" className="py-2 block">
														Project Details
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/pricing_inner" className="py-2 block">
														Pricing Plan
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/testimonial" className="py-2 block">
														Testimonial
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/appointment" className="py-2 block">
														Appointment
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/faq_inner" className="py-2 block">
														Faq
													</Link>
												</li>
											</div>
										</ul>
									</div>
								</NavLink>
								{/* blog sub menu link */}
								<NavLink
									className={`${({ isActive, isPending }) =>
										isPending
											? 'pending'
											: isActive
											? 'active'
											: ''} text-white text-left hover:text-PrimaryColor-0 lg:border-b-0 px-3 lg:px-2 xl:px-3 w-full block transition-all duration-300 group relative `}
									to="#"
								>
									<span className="flex items-center">
										Blog
										<BiChevronDown className="ml-1" />
									</span>
									<div className="absolute pt-5 lg:pt-8 z-20">
										<ul className="shadow-lg hidden group-hover:block rounded-sm text-white w-[240px] text-left transition-all duration-500 text-sm sm:text-base py-4 bg-SecondaryColor-0">
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/blog_grid" className="py-2 block">
														Blog Grid
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/blog_list" className="py-2 block">
														Blog List
													</Link>
												</li>
											</div>
											<div className="px-5 group hover:bg-PrimaryColor-0">
												<li className="hover:ml-3 duration-300">
													<Link to="/blog_details" className="py-2 block">
														Blog Details
													</Link>
												</li>
											</div>
										</ul>
									</div>
								</NavLink>
								<NavLink
									className={`${({ isActive, isPending }) =>
										isPending ? 'pending' : isActive ? 'active' : ''} text-white text-left lg:border-b-0 px-3 lg:px-2 xl:px-3 w-full block transition-all duration-300`}
									to="/contact"
								>
									Contact
								</NavLink>
							</ul>
							<div className="hidden lg:flex items-center ">
								<button>
									<IoSearch className="text-2xl ml-2 mr-10 text-PrimaryColor-0" />
								</button>
								<Link to="/service" className="header-btn">
									Get A Tree
								</Link>
								{UserInfo && UserInfo.isLogedin && UserInfo.expirationTime > Math.floor(Date.now() / 1000) ? (
									<div
										//  className="px-5 group hover:bg-PrimaryColor-0"
										className={` 
                        text-white   transition-all duration-300 group `}
									>
										<span className=" rounded-lg    bg-JzSecondaryColor-0  px-4 py-2  bold ms-4 text-xl" style={{ fontWeight: 'bolder' }}>
											{/* {UserInfo.FirstName } */}
											{UserInfo.FirstName.charAt(0)} {/* <BiChevronDown className="ml-1" /> */}
										</span>
										<div className="absolute pt-3 lg:pt-8 z-20 rounded-lg">
											<ul className="shadow-lg hidden group-hover:block rounded-lg text-white w-[240px] text-left transition-all duration-500 text-sm sm:text-base py-4 bg-SecondaryColor-0">
												{/* <div className="px-5 group ">
                          <li className="hover:ml-3 duration-300">
                           {UserInfo.Email}
                          </li>
                          <li className="hover:ml-3 duration-300">
                          Welcome {UserInfo.Name}
                          </li>
                        </div> */}
												<div className="px-5 group hover:bg-PrimaryColor-0">
													<li className="hover:ml-3 duration-300">
														<Link to="/Profile" className="py-2 block">
															Profile
														</Link>
													</li>
												</div>
												<div className="px-5 group hover:bg-PrimaryColor-0">
													<li className="hover:ml-3 duration-300">
														<Link to="/blog_list" className="py-2 block">
															Settings
														</Link>
													</li>
												</div>
												<div className="px-5 group hover:bg-PrimaryColor-0">
													<li className="hover:ml-3 duration-300">
														<Link className="py-2 block" onClick={() => logout()}>
															Logout
														</Link>
													</li>
												</div>
												<div className="px-5 group ">
													<li className="hover:ml-3 duration-300">Welcome {UserInfo.Name}</li>
													<li className="hover:ml-3 duration-300">{UserInfo.Email}</li>
													<li className="hover:ml-3 duration-300">Session expired at {SessionExpired}</li>
												</div>
											</ul>
										</div>
									</div>
								) : (
									<Link to="/Profile" className="ms-2 header-btn">
										Login{' '}
									</Link>
								)}
								{/* </Link> */}
							</div>
						</div>
					</div>
				</div>
			</header>
		</nav>
	);
};

export default Navbar;
