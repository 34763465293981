import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
	name: 'data',
	initialState: {
		value: null, // Define the default state
		inv: [], // Define the default state
	},
	reducers: {
		saveData: (state, action) => {
			state.value = action.payload; // Save data to state
		},
		saveInvoice: (state, action) => {
			state.inv = action.payload; // Save data to state
		},
		ClearInv: (state) => {
			state.inv = null;
		},
	},
});

export const { saveData, saveInvoice, ClearInv } = dataSlice.actions; // Export actions
export default dataSlice.reducer; // Export the reducer
