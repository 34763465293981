import { useEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { MdAdd } from 'react-icons/md';
import { uploadUrl } from '../../../Components/urls';
import { Link } from 'react-router-dom';
import { useSession } from '../../../Components/useSession';
import { GetOrders } from '../../../data/CheckOutController';

export const RelatedModal = ({ setNewService, setsetNewService, HandelRelatedAddService, handelAddOwnerService, showRelatedModal, HandelCloseRelatedModal, index, Invoices }) => {
	const { SessionExpired } = useSession();
	const [Orders, setOrders] = useState([]);

	const handleOverlayClick = (e) => {
		if (e.target.id === 'modal-overlay') {
			HandelCloseRelatedModal();
		}
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				const ords = await GetOrders();
				setOrders(ords);
			} catch (error) {
				console.log(error);
			}
		};
		if (showRelatedModal) fetch();
	}, [showRelatedModal]);

	return (
		<>
			{showRelatedModal && (
				<>
					{/* Modal Overlay */}
					<div id="modal-overlay" className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-90 " onClick={handleOverlayClick}>
						{/* Modal Content */}
						<div className=" fixed h-full sm:w-full md:w-full lg:w-auto px-5 lg:px-24 py-5 custom-scrollbar left-0 bg-white lg:rounded-lg shadow-lg z-[9999]">
							{/* Header */}
							<div className="bg-white-100 p-4 rounded-t-lg border-b">
								<h2 className="text-xl font-semibold text-gray-700">Add Related Service to order {index + 1}</h2>
							</div>

							{/* Body */}
							<div className="grid sm:grid-row-2 md:grid-row-2 lg:grid-row-2 gap-4 ">
								<div className="flex flex-col justify-center items-center col-span-1 rounded-lg ">
									<div className="w-[100%] font-bold text-[22px] flex-left p-2 ">Related Service</div>
									<div className="w-[100%] font-bold text-[16px]   lg:flex-left p-2 ">
										{Invoices[index].RelatedFor
											? Invoices[index].RelatedFor.filter((rlt) => rlt.RelatedSubPrerequisite === 0).map((rlt, i) => (
													<div className="p-2 " key={i}>
														<div className="flex flex-col lg:flex-row item-left lg:items-center gap-2 ">
															<img
																src={uploadUrl + rlt.RelatedSubServicePhoto}
																alt="Sub Service"
																style={{
																	maxHeight: '250px',
																	maxWidth: '250px',
																	backgroundColor: 'black',
																}}
															/>
															<div className="flex-row text-[24px] text-left mx-2 ">
																<div>{rlt.RelatedSubServiceTitle}</div>
																<div>{rlt.RelatedSubServiceDesc}</div>
																<div>${rlt.RelatedSubPrice}</div>
																<button onClick={() => HandelRelatedAddService(i)} className="bg-gray-800 text-white text-[14px]  flex-bottom   rounded hover:bg-green-900 transition p-2">
																	Adopt Now
																</button>
															</div>
														</div>
													</div>
											  ))
											: ''}
									</div>
								</div>
								{SessionExpired > 0 ? (
									<div className="overflow-x-auto">
										<table className="table-auto w-full border-collapse border border-gray-300 text-sm text-left">
											<thead className="bg-gray-100 text-gray-700">
												<tr>
													<td className="border border-gray-300 px-4 py-2 font-medium">Order</td>
													<td className="border border-gray-300 px-4 py-2 font-medium">Date</td>
													<td className="border border-gray-300 px-4 py-2 font-medium">Owners</td>
													<td className="border border-gray-300 px-4 py-2 font-medium">Services</td>
												</tr>
											</thead>
											<tbody>
												{Orders &&
													Orders.filter((or) => or.OrderType == 3).map((ord, o) => (
														<tr key={o} className="hover:bg-gray-50 even:bg-gray-50 transition-colors">
															<td className="border border-gray-300 px-4 py-2">{ord.OrderID}</td>
															<td className="border border-gray-300 px-4 py-2">{ord.Created_at}</td>
															<td className="border border-gray-300 px-4 py-2">
																{ord.OrderArray &&
																	ord.OrderArray.map(
																		(arr) =>
																			arr.Owners &&
																			arr.Owners.map((Own, on) => (
																				<div key={on} className="mb-1">
																					{Own.Firstname}
																				</div>
																			)),
																	)}
															</td>
															<td className="border border-gray-300 px-4 py-2">
																{ord.OrderArray &&
																	ord.OrderArray.map(
																		(arr, a) =>
																			arr.Related && (
																				<div className="flex items-center justify-between space-x-2" key={a}>
																					<span>{arr.Related.RelatedSubServiceTitle}</span>
																					<MdAdd className="text-xl text-blue-500 cursor-pointer" onClick={() => handelAddOwnerService(arr)} />
																				</div>
																			),
																	)}
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								) : (
									<div className="flex flex-col justify-center items-center col-span-1 rounded-lg ">
										<div className="w-[100%] font-bold text-[18px] flex-left p-2 ">
											Or click{' '}
											<u className="text-JzSecondaryColor-0">
												<Link to={'/Profile?j=service_details'}>here</Link>
											</u>{' '}
											to login and add your owen
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};
