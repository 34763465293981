import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import router from "./Router/Router.jsx";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store.js";
// import 'bootstrap/dist/css/bootstrap.min.css';

// import { HelmetProvider } from "react-helmet-async";
// import HelmetChanger from "./Shared/Helmet/Helmet.jsx";

// const expirationDate = new Date("2024-09-22");
// const currentDate = new Date();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// if (currentDate > expirationDate) {
//   ReactDOM.createRoot(document.getElementById("root")).render(
//     <React.StrictMode>
//       <div style={{ textAlign: "center", padding: "50px", fontSize: "24px" }}>
//         The application has expired. Please contact support.
//       </div>
//     </React.StrictMode>
//   );
// } else {
//   ReactDOM.createRoot(document.getElementById("root")).render(
//     <React.StrictMode>
//       {/* <HelmetProvider context={HelmetChanger}> */}
//       <RouterProvider router={router} />
//       {/* </HelmetProvider> */}
//     </React.StrictMode>
//   );
// }
