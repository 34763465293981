import { Checkbox, Typography } from '@material-tailwind/react';
import { MdCheck, MdClose } from 'react-icons/md';
import { useJWT } from '../data/useUserInfo';
import { useSession } from './useSession';
import { Link, useNavigate } from 'react-router-dom';
import { NewOrder } from '../data/CheckOutController';
import { useSelector } from 'react-redux';

export const UseCheckOut = ({ finalInvoice, show, setShow, setInvoices, dispatch, ClearInv }) => {
	// const token = useSelector((state) => (state.users ? state.users.value : ''));
	const { users, status, error } = useSelector((state) => state.users);
	const token = status === 'succeded' ? users.token.jwt : [];
	const { UserInfo } = useJWT();
	const { SessionExpired } = useSession();

	const TABLE_HEAD = ['Service', 'Price', 'Addon', 'Related', 'Owners', 'Total'];
	const TotalPrice = finalInvoice.reduce((acc, inv) => acc + (inv.TotalInvPrice || 0), 0);
	// const CheckOut = [
	//   finalInvoice.forEach((element) => {
	//     Service = inv.SubServiceTitle;
	//   }),
	// ];
	const CheckOut = finalInvoice.map((element) => ({
		Service: element.SubServiceTitle,
		Price: element.SubPrice,
		Addon: element.NewSubService,
		Related: element.NewRelatedService,
		Owners: element.persons,
		Total: element.TotalInvPrice,
	}));
	const navigate = useNavigate();
	const HandelCheckOut = () => {
		if (UserInfo && UserInfo.isLogedin) {
			NewOrder(CheckOut, token);
			dispatch(ClearInv());
			setInvoices([]);
		} else {
			alert('Login before checkout...');
			navigate('/Profile?j=service_details');
		}
	};
	return (
		<>
			{/* Overlay */}
			<div className={`fixed inset-0 bg-black bg-opacity-50 z-99 ${show ? 'block' : 'hidden'}`} onClick={() => setShow(!show)}></div>

			{/* Off-canvas */}
			<div
				className={`fixed custom-scrollbar bottom-0 left-0 w-full bg-gray-800 text-white z-[1100] transform ${show ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300`}
				style={{ opacity: '0.98', height: '50vh' }}
			>
				<MdClose onClick={() => setShow(false)} className="text-[34px] bold pointer text-red-500 rounded m-4" style={{ cursor: 'pointer' }} />
				<div className="flex flex-col h-full">
					{/* Table */}
					<div className="relative flex-1">
						<table className="w-full min-w-max table-auto text-left border-collapse">
							<thead className="sticky top-0 bg-gray-800 z-[1200]">
								<tr>
									{TABLE_HEAD.map((head) => (
										<th key={head} className="border-b border-blue-gray-100 bg-blue-gray-50 p-4 text-blue-gray-700 font-normal leading-none opacity-70">
											{head}
										</th>
									))}
								</tr>
							</thead>
							<tbody className="overflow-y-auto h-full  ">
								{finalInvoice.map((inv, i) => {
									const isLast = i === finalInvoice.length - 1;
									const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50';

									return (
										<tr key={i} className=" ">
											<td className={classes}>{inv.SubServiceTitle}</td>
											<td className={classes}>${inv.SubPrice}</td>
											<td className={classes}>
												<ul>
													{inv.NewSubService &&
														inv.NewSubService.map((nsv, idx) => (
															<li key={idx}>
																{nsv.SubServiceTitle} ${nsv.SubPrice}
															</li>
														))}
												</ul>
											</td>
											<td className={classes}>
												{inv.NewRelatedService ? (
													<div>
														{inv.NewRelatedService.RelatedSubServiceTitle} ${inv.NewRelatedService.RelatedSubPrice}
													</div>
												) : (
													''
												)}
											</td>
											<td className={classes}>{inv.persons && inv.persons.map((pr, idx) => <div key={idx}>{pr.Email}</div>)}</td>
											<td className={classes}>${inv.TotalInvPrice}</td>
										</tr>
									);
								})}
							</tbody>
							<tfoot className="sticky bottom-0 bg-gray-800 z-[1200]">
								<tr>
									<th colSpan={3}></th>
									<th colSpan={1}>
										<button
											onClick={() => HandelCheckOut() + setShow(!show)}
											className="font-AlbertSans flex gap-2 items-center bg-JZPrimaryColor-0 p-6 w-full h-[28px] rounded-md justify-center z-10 relative before:absolute before:top-0 before:right-0 before:scale-0 before:-z-10 before:w-full before:h-full before:bg-SecondaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:scale-100 hover:text-white"
										>
											CheckOut
											<MdCheck />
										</button>
									</th>
									<th colSpan={1} align="center">
										Total
									</th>
									<th colSpan={1} className="p-4">
										${TotalPrice}
									</th>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};
