import { saveData } from '../redux/dataSlice';

const getToken = () => {
	const token = localStorage.getItem('token');
	return JSON.parse(token);
};
export const CustomerLogin = async (Userdata) => {
	try {
		const response = await fetch('/api/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
			},
			body: JSON.stringify({ action: 'CustomerLogin', Userdata: Userdata }),
			// credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
		}
		const data = await response.json();
		// localStorage.setItem('token', JSON.stringify(data.token.jwt))
		// if (data.status) {
		//   dispath(saveData(JSON.stringify(data.token.jwt)));
		// }  else{
		//   console.log(data.log)
		// }
		return data;
	} catch (error) {
		console.error('Error fetching data:', error);
		console.log(`Error message: ${error.message}`);
	}
};

export const NewCustomer = async (row) => {
	console.log(row);
	try {
		const jwtToken = getToken();
		const response = await fetch('/api/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
				Authorization: `Bearer ${jwtToken}`,
			},
			body: JSON.stringify({ action: 'NewCustomer', data: row }),
			// credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
		}
		const data = await response.json();
		console.log(data);
		return data.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		console.log(`Error message: ${error.message}`);
	}
};
export const EditUser = async (row) => {
	try {
		const jwtToken = getToken();
		const response = await fetch('/api/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
				Authorization: `Bearer ${jwtToken}`,
			},
			body: JSON.stringify({ action: 'EditUser', data: row }),
			// credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
		}
		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		console.log(`Error message: ${error.message}`);
	}
};

export const UserResetPassword = async (row) => {
	try {
		const jwtToken = getToken();
		const response = await fetch('/api/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
				Authorization: `Bearer ${jwtToken}`,
			},
			body: JSON.stringify({ action: 'UserResetPassword', data: row }),
			// credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
		}
		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		console.log(`Error message: ${error.message}`);
	}
};
