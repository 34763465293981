import { useState } from 'react';
import { MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane } from 'mdb-react-ui-kit';
import { CustomerProfile } from './Customer/CustomerProfile';
import { CustomerServices } from './Customer/CustomerServices';
import { CustomerInvoices } from './Customer/CustomerInvoices';

export const Customers = ({ UserInfo }) => {
	const [justifyActive, setJustifyActive] = useState('tab1');

	const handleJustifyClick = (value) => {
		if (value === justifyActive) {
			return;
		}
		setJustifyActive(value);
	};
	let activeContent;
	if (justifyActive === 'CustomerProfile') {
		activeContent = <CustomerProfile UserInfo={UserInfo} />;
	} else if (justifyActive === 'CustomerServices') {
		activeContent = <CustomerServices UserInfo={UserInfo} />;
	} else if (justifyActive === 'CustomerInvoices') {
		activeContent = <CustomerInvoices UserInfo={UserInfo} />;
	}
	return (
		<>
			<div className="mb-4 border-b border-gray-200 dark:border-gray-700">
				<ul
					className="flex flex-wrap -mb-px text-sm font-medium text-center"
					id="default-styled-tab"
					data-tabs-toggle="#default-styled-tab-content"
					data-tabs-active-classNamees="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500"
					data-tabs-inactive-classNamees="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300"
					role="tablist"
				>
					<li className="me-2" role="presentation">
						<button className="inline-block p-4 border-b-2 rounded-t-lg" role="tab" onClick={() => handleJustifyClick('CustomerProfile')}>
							Profile
						</button>
					</li>
					<li className="me-2" role="presentation">
						<button className="inline-block p-4 border-b-2 rounded-t-lg" role="tab" onClick={() => handleJustifyClick('CustomerServices')}>
							Services
						</button>
					</li>
					<li className="me-2" role="presentation">
						<button className="inline-block p-4 border-b-2 rounded-t-lg" role="tab" onClick={() => handleJustifyClick('CustomerInvoices')}>
							Invoices
						</button>
					</li>
				</ul>
			</div>
			<div>{activeContent}</div>
		</>
	);
};
