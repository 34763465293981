import { useDispatch } from 'react-redux';
import { ClearInv, saveInvoice } from '../../../../redux/dataSlice';
import { FaArrowRight } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';

export const HandelDeleteOwnerButton = ({ Invoices, setInvoices, invoiceIndex, personIndex, persons }) => {
	const dispatch = useDispatch();

	const HandelDeleteOwner = async () => {
		const updatedInvoice = {
			...Invoices[invoiceIndex],
			persons: Invoices[invoiceIndex].persons.filter((_, index) => index !== personIndex),
		};
		const updatedInvoices = [...Invoices];
		updatedInvoices[invoiceIndex] = updatedInvoice;
		setInvoices(updatedInvoices);
		dispatch(saveInvoice(JSON.stringify(updatedInvoices)));
	};

	return (
		<span onClick={() => HandelDeleteOwner()}>
			<MdDelete className="text-xl text-[#f00] hover:text-[#ccc]  mx-6" style={{ cursor: 'pointer' }} />
		</span>
	);
};
