export const NewOrder = async (row, jwtToken) => {
	try {
		// const jwtToken = getToken();
		const response = await fetch('/api/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
				Authorization: `Bearer ${jwtToken}`,
			},
			body: JSON.stringify({ action: 'NewOrder', data: row }),
			// credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
		}
		const data = await response.json();
		return data.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		console.log(`Error message: ${error.message}`);
	}
};
export const GetOrders = async (row, jwtToken) => {
	try {
		// const jwtToken = getToken();
		const response = await fetch('/api/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
				Authorization: `Bearer ${jwtToken}`,
			},
			body: JSON.stringify({ action: 'GetOrders', data: row }),
			// credentials: "include",
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
		}
		const data = await response.json();
		// console.log(data);
		return data.data;
	} catch (error) {
		console.error('Error fetching data:', error);
		console.log(`Error message: ${error.message}`);
	}
};
