export const JWT = async (jwtToken) => {
	try {
		const response = await fetch('/api/authenticate.php', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Connection: 'keep-alive',
				Authorization: `Bearer ${jwtToken}`,
			},
		});

		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Error fetching data:', error);
		console.log(`Error message: ${error.message}`);
	}
};
