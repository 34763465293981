import BreadCrumb from '../../../Shared/BreadCrumb/BreadCrumb';
import { FaArrowLeft, FaArrowRight, FaArrowRightLong } from 'react-icons/fa6';
import { MdAdd, MdAddCard, MdClose, MdContactMail, MdCurrencyExchange, MdDelete, MdDescription, MdEmail, MdPerson, MdPhone, MdRemove } from 'react-icons/md';
import { UseServicesCards } from '../../../Components/useServices';
import { uploadUrl } from '../../../Components/urls';
import { useEffect, useState } from 'react';
import { OwnerModal } from './OwnersModal';
import { RelatedModal } from './RelatedModal';
import { UseCheckOut } from '../../../Components/useChekOut';
import { useDispatch } from 'react-redux';
import { ClearInv, saveInvoice } from '../../../redux/dataSlice';
import { useSelector } from 'react-redux';
import { dataUpdate } from '../../../Components/useUpdate';
import { HandleClearInvButton } from './func/HandelClearInv';
import { HandelDeleteOwnerButton } from './func/HandelDeleteOwner';
import { useSession } from '../../../Components/useSession';
import { decrypt, encrypt } from '../../../Components/encdecrypt';
import { HandelAddInvoice, HandelRemoveInvoice, UseInvoice } from '../../../Components/useInvoice';

const ServiceDetails = () => {
	const dispatch = useDispatch();
	const { SessionExpired } = useSession();

	const [show, setCheckOutModalShow] = useState(false);
	const [Msg, setMsg] = useState('msg');
	const [NewPerson, setNewPerson] = useState({
		Firstname: '',
		MiddleName: '',
		LastName: '',
		Email: '',
		Phone: '',
	});
	const [NewService, setNewService] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [showRelatedModal, setShowRelatedModal] = useState(false);
	const [index, setIndex] = useState();

	const [Invoices, setInvoices] = UseInvoice();
	const serviceCards = UseServicesCards();
	const SubService = serviceCards[0]?.SubService || [];
	const SubPrerequisite = SubService.filter((sr) => sr.SubPrerequisite && sr.OncePerOrder);

	const finalInvoice = [...Invoices, ...SubPrerequisite];
	const subtotal = finalInvoice.reduce((acc, invoice) => acc + invoice.TotalInvPrice, 0);

	// Start Modals func
	const HandelShowOwnerModal = (i) => {
		setShowModal(true);
		setIndex(i);
	};

	const HandelCloseOwnerModal = () => {
		setShowModal(false);
	};

	const HandelShowRelatedModal = (i) => {
		setShowRelatedModal(true);
		setIndex(i);
	};
	const HandelCloseRelatedModal = () => {
		setShowRelatedModal(false);
	};
	// End Modals func

	const HandelRelatedAddService = async (i) => {
		// Ensure RelatedFor[i] is valid and push it to relatedFor array if necessary
		const relatedFor = Invoices[index]?.RelatedFor?.[i] ? Invoices[index].RelatedFor[i] : [];

		const updatedInvoice = {
			...Invoices[index],
			NewRelatedService: relatedFor,
			TotalInvPrice: Invoices[index].NewSubService
				? relatedFor.RelatedSubPrice + Invoices[index].SubPrice + Invoices[index].NewSubService.reduce((acc, nsb) => acc + (nsb.SubPrice || 0), 0)
				: relatedFor.RelatedSubPrice + Invoices[index].SubPrice,

			// TotalInvPrice: relatedFor.RelatedSubPrice + Invoices[index].TotalInvPrice
		};

		const updatedInvoices = [...Invoices];
		updatedInvoices[index] = updatedInvoice;
		setInvoices(updatedInvoices);
		setShowRelatedModal(false);
		const data = JSON.stringify(updatedInvoices);
		dispatch(saveInvoice(encrypt(data)));
	};

	const handelAddOwnerService = async (i) => {
		// Ensure RelatedFor[i] is valid and push it to relatedFor array if necessary
		const relatedFor = i.Related;
		const NewPerson = i.Owners;

		const updatedInvoice = {
			...Invoices[index],
			NewRelatedService: { ...relatedFor, isOwned: true },
			persons: [...(Invoices[index].persons || []), ...NewPerson],
			TotalInvPrice: Invoices[index].NewSubService
				? relatedFor.RelatedSubPrice + Invoices[index].SubPrice + Invoices[index].NewSubService.reduce((acc, nsb) => acc + (nsb.SubPrice || 0), 0)
				: relatedFor.RelatedSubPrice + Invoices[index].SubPrice,
		};

		const updatedInvoices = [...Invoices];
		updatedInvoices[index] = updatedInvoice;
		setInvoices(updatedInvoices);
		setShowRelatedModal(false);
		const data = JSON.stringify(updatedInvoices);
		dispatch(saveInvoice(encrypt(data)));
	};

	const HandelRemoveService = async (sub, i) => {
		// console.log(sub);
		const updatedInvoice = {
			...Invoices[i],
			NewSubService: Invoices[i].NewSubService.filter((nsv) => nsv.SubServiceID !== sub.SubServiceID),
			TotalInvPrice: Invoices[i].TotalInvPrice - sub.SubPrice * sub.Quantity,
		};

		// Update the invoices array
		const updatedInvoices = [...Invoices];
		updatedInvoices[i] = updatedInvoice; // Update the specific invoice at index `i`

		// Set the updated invoices array
		setInvoices(updatedInvoices);
		const data = JSON.stringify(updatedInvoices);
		dispatch(saveInvoice(encrypt(data)));
	};
	const HandelSubAddService = async (sub, i, e) => {
		const updatedInvoice = {
			...Invoices[i],
			NewSubService: Invoices[i].NewSubService
				? Invoices[i].NewSubService.map((service) =>
						service.SubServiceID === sub.SubServiceID
							? {
									...service,
									Quantity: service.Quantity + (e ? 1 : service.Quantity > 1 ? -1 : false),
							  }
							: service,
				  ).concat(!Invoices[i].NewSubService.some((service) => service.SubServiceID === sub.SubServiceID) ? { ...sub, Quantity: e ? 1 : -1 } : [])
				: [{ sub, ...sub, Quantity: 1 }],

			TotalInvPrice: Invoices[i].NewRelatedService
				? (Invoices[i].NewSubService ? Invoices[i].NewSubService.reduce((acc, nsb) => acc + (nsb.SubPrice || 0), 0) + sub.SubPrice : sub.SubPrice) + Invoices[i].SubPrice + Invoices[i].NewRelatedService.RelatedSubPrice
				: (Invoices[i].NewSubService ? Invoices[i].NewSubService.reduce((acc, nsb) => acc + (nsb.SubPrice * nsb.Quantity || 0), 0) + (e ? sub.SubPrice : sub.Quantity > 1 && -sub.SubPrice) : sub.SubPrice) +
				  Invoices[i].SubPrice,
		};

		// Update the invoices array
		const updatedInvoices = [...Invoices];
		updatedInvoices[i] = updatedInvoice; // Update the specific invoice at index `i`

		// Set the updated invoices array
		setInvoices(updatedInvoices);
		const data = JSON.stringify(updatedInvoices);
		dispatch(saveInvoice(encrypt(data)));
	};
	const HandelAddOwner = async (index) => {
		const updatedInvoice = {
			...Invoices[index],
			persons: [...(Invoices[index].persons || []), NewPerson],
		};
		const updatedInvoices = [...Invoices];
		updatedInvoices[index] = updatedInvoice;
		setInvoices(updatedInvoices);
		const data = JSON.stringify(updatedInvoices);
		dispatch(saveInvoice(encrypt(data)));
		setNewPerson({
			Firstname: '',
			MiddleName: '',
			LastName: '',
			Email: '',
			Phone: '',
		});
		setShowModal(false);
	};

	// if (!card) return null;
	return (
		<>
			<UseCheckOut show={show} setShow={setCheckOutModalShow} finalInvoice={finalInvoice} setInvoices={setInvoices} dispatch={dispatch} ClearInv={ClearInv} />
			<OwnerModal NewPerson={NewPerson} setNewPerson={setNewPerson} HandelAddOwner={HandelAddOwner} index={index} HandelCloseOwnerModal={HandelCloseOwnerModal} showModal={showModal} />
			<RelatedModal
				NewService={NewService}
				setNewService={setNewService}
				HandelRelatedAddService={HandelRelatedAddService}
				handelAddOwnerService={handelAddOwnerService}
				index={index}
				HandelCloseRelatedModal={HandelCloseRelatedModal}
				showRelatedModal={showRelatedModal}
				Invoices={Invoices}
			/>
			<BreadCrumb
				breadCrumbPhoto={uploadUrl + '/' + 'upload/ServicesPhoto/0uoI4Uqfgy_20240926204453.jpg'}
				breadCrumbTitle={'card.ServiceCardTitle'}
				breadCrumbText={'card.ServiceCardText'}
				breadCrumbIcon={<FaArrowRightLong />}
				breadCrumbLink={'Serivces'}
				breadCrumbIcon2={<FaArrowRightLong />}
				breadCrumbContent={'card.ServiceCardTitle'}
			/>
			<section className="py-[20px] bg-white">
				<div className="Container">
					<div className="grid grid-cols-3 gap-[70px]">
						<div className="col-span-3 lg:col-span-2">
							{SubPrerequisite &&
								SubPrerequisite.map((rlt, i) => (
									<div className="grid sm:grid-cols-3 lg:grid-cols-3 gap-[11px] p-7 rounded border" key={i}>
										{/* Image Section */}
										<div className="col-span-1 lg:col-span-1">
											<img src={uploadUrl + rlt.SubServicePhoto} alt="Sub Service" style={{ maxHeight: '100px', backgroundColor: 'black' }} />
										</div>
										<div className="col-span-2 lg:col-span-2">
											<h1 className="text-[20px] font-bold">{rlt.SubServiceTitle}</h1>
											<h1 className="text-[12px] font-bold">{rlt.SubServiceTitle}</h1>
										</div>

										<div className="col-span-3 lg:col-span-3 flex items-end justify-end  ">${rlt.SubPrice}</div>
									</div>
								))}
							{Invoices &&
								Invoices.filter((rtl) => rtl.OncePerOrder === 0).map((inv, i) => (
									<div className="grid  grid-cols-1 lg:grid-cols-3 gap-[11px] p-2  lg:p-7 rounded border mt-2" key={i}>
										<div className="col-span-3 lg:col-span-3">Order {i + 1}</div>
										<div className="col-span-3 lg:col-span-1">
											<img src={uploadUrl + inv.SubServicePhoto} alt="Sub Service" style={{ maxHeight: '100px' }} />
											{inv && inv.persons && inv.persons.length > 0 && (
												<div className="bg-[#f3f4f8] border rounded p-2">
													Owner{inv.persons.length > 1 && 's'}
													{inv.persons &&
														inv.persons.map((pr, pi) => (
															<div
																key={pi}
																className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 lg:gap-2 md:gap-2
                                        mt-1 mb-[12px]    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
															>
																<span className="font-AlbertSans flex gap-0 my-[2px]">
																	<MdPerson className="text-xl text-PrimaryColor-0 " />
																	{pr.Firstname} {pr.MiddleName} {pr.LastName}
																</span>
																<span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
																	<MdEmail className="text-xl text-PrimaryColor-0 " />
																	{pr.Email}
																</span>
																<span className=" font-AlbertSans flex gap-0 my-[2px] ">
																	<MdPhone className="text-xl text-PrimaryColor-0 " /> {pr.CountryCode} {pr.Phone}
																	{inv.NewRelatedService && inv.NewRelatedService.isOwned ? '' : <HandelDeleteOwnerButton Invoices={Invoices} setInvoices={setInvoices} invoiceIndex={i} personIndex={pi} />}
																</span>
															</div>
														))}
												</div>
											)}
										</div>
										<div className="col-span-1 lg:col-span-2">
											<h1 className="text-[20px] font-bold">
												{inv.SubServiceTitle}
												<span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
													<MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
													{inv.SubPrice}
												</span>
											</h1>
											<h1 className="text-[16px] font-bold">{inv.SubServiceDesc}</h1>
											{inv.HaveContact || inv.RelatedFor ? <div className=" font-bold text-JzSecondaryColor-0 mt-2">Required:</div> : ''}

											{inv?.RelatedFor || inv.HaveContact ? (
												<div className="flex items-center justify-left gap-[24px] text-[12px] my-2">
													{(inv?.RelatedToService && inv?.RelatedFor) || inv.HaveContact ? (
														<div className="border rounded-md flex gap-[24px] p-2 bg-[#f3f4f8]">
															{inv.HaveContact ? (
																<>
																	<div onClick={() => HandelShowOwnerModal(i)} style={{ cursor: 'pointer' }}>
																		<div>
																			<MdContactMail className="text-[#000] text-[33px]" />{' '}
																		</div>
																		<div>Add Owner</div>
																	</div>
																</>
															) : (
																''
															)}
															{inv?.RelatedToService && inv?.RelatedFor ? (
																<div style={{ cursor: 'pointer' }} onClick={() => HandelShowRelatedModal(i)}>
																	<div>
																		<MdAddCard className="text-[#000] text-[33px]" />{' '}
																	</div>
																	<div>
																		{' '}
																		{inv.NewRelatedService ? 'Edit ' : 'Add '}
																		related service
																	</div>
																</div>
															) : (
																''
															)}
														</div>
													) : (
														''
													)}
													<div className="border rounded-md flex gap-[24px] p-2 bg-[#f3f4f8]">
														{SubService &&
															SubService.filter((sub) => !sub.SubPrerequisite && !sub.OncePerOrder && sub.RelatedToService && sub.SubServiceID !== inv.SubServiceID).map((sub, ii) => (
																<div key={ii} style={{ cursor: 'pointer' }} onClick={() => HandelSubAddService(sub, i, true)}>
																	<div>
																		<img src={uploadUrl + sub.SubServicePhoto} draggable="false" className="transition-all duration-500 group-hover:scale-110 w-[33px]" />
																	</div>
																	<div>Add {sub.SubServiceTitle} </div>
																</div>
															))}
													</div>
												</div>
											) : (
												''
											)}

											<div className="bg-[#f3f4f8] border rounded p-2">
												Service
												{inv && inv.NewRelatedService && (
													<>
														{/* {console.log(inv.NewRelatedService)} */}
														<div
															className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:gap-2 md:gap-2
                                        mt-1 mb-[12px]
                                    // bg-[#f3f4f8]
                                    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
														>
															<div className="overflow-hidden rounded">
																<img src={uploadUrl + inv.NewRelatedService.RelatedSubServicePhoto} draggable="false" className="transition-all duration-500 group-hover:scale-110" />
															</div>
															<div className="overflow-hidden rounded">
																<span className="font-AlbertSans flex gap-0 my-[2px]">
																	<MdDescription className="text-xl text-PrimaryColor-0 mr-2 " />
																	{inv.NewRelatedService.RelatedSubServiceTitle}
																</span>
																<span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
																	<MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
																	{inv.NewRelatedService.RelatedSubPrice}
																</span>

																{/* {inv.HaveContact ? ( */}
																<span
																	className="font-AlbertSans flex gap-0 my-[2px] text-[12px]"
																	onClick={() => HandelShowOwnerModal(i)}
																	style={{
																		cursor: 'pointer',
																		fontWeight: 'bolder',
																		animation: 'bounce 1s infinite',
																	}}
																>
																	<MdContactMail className="text-xl text-PrimaryColor-0 mr-2" />
																	Add Order Owner
																</span>
																{/* )} */}
															</div>
														</div>
													</>
												)}
												{inv.NewSubService &&
													inv.NewSubService.map((nsv, iii) => (
														<div
															key={iii}
															className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 lg:gap-1 md:gap-2
                                        mt-1 mb-[12px]
                                    // bg-[#f3f4f8]
                                    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
														>
															<div className="overflow-hidden   rounded">
																<img src={uploadUrl + nsv.SubServicePhoto} draggable="false" className="transition-all duration-500 group-hover:scale-110 w-[33px]" />
															</div>

															<div className="flex-3">
																Quantity
																<span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
																	{/* <MdOutlineProductionQuantityLimits className="text-xl text-PrimaryColor-0 mr-2" /> */}
																	<MdRemove className="text-xl text-[#f00] mr-2" onClick={() => HandelSubAddService(nsv, i, false)} style={{ cursor: 'pointer' }} />
																	<input type="text" value={nsv.Quantity} className=" text-PrimaryColor-0 text-[18px] border w-[54px] h-[24px] text-center p-2" />
																	<MdAdd className="text-xl text-PrimaryColor-0 mr-2" onClick={() => HandelSubAddService(nsv, i, true)} style={{ cursor: 'pointer' }} />
																</span>
															</div>
															<div className="overflow-hidden rounded">
																<span className="font-AlbertSans flex gap-0 my-[2px]">
																	<MdDescription className="text-xl text-PrimaryColor-0 mr-2 " />
																	{nsv.SubServiceTitle}
																</span>
																<span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
																	<MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
																	{nsv.SubPrice * nsv.Quantity}
																</span>

																<span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]" style={{ cursor: 'pointer' }} onClick={() => HandelRemoveService(nsv, i)}>
																	<MdClose className="text-xl text-[#f00] mr-2" /> Remove
																</span>
															</div>
														</div>
													))}
											</div>
											{/* )} */}
											{inv && inv.NewService && inv.NewService.length > 0 && (
												<div className="">
													Service{inv.NewService.length > 1 && 's'}
													{inv.NewService &&
														inv.NewService.map((rltsrv, i) => (
															<div
																key={i}
																className="font-AlbertSans text-[#000000]
                                     grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 lg:gap-2 md:gap-2
                                        mt-1 mb-[12px]
                                    // bg-[#f3f4f8]
                                    bg-[#fff]
                                    rounded
                                    row
                                    p-2
                                     text-[12px]
                                    "
															>
																<div className="overflow-hidden rounded">
																	<img src={uploadUrl + rltsrv.RelatedSubServicePhoto} draggable="false" className="transition-all duration-500 group-hover:scale-110" />
																</div>
																<div className="overflow-hidden rounded">
																	<span className="font-AlbertSans flex gap-0 my-[2px]">
																		<MdDescription className="text-xl text-PrimaryColor-0 mr-2 " />
																		{rltsrv.RelatedSubServiceTitle}
																	</span>
																	<span className="font-AlbertSans flex gap-0 my-[2px] text-[12px]">
																		<MdCurrencyExchange className="text-xl text-PrimaryColor-0 mr-2" />
																		{rltsrv.RelatedSubPrice}
																	</span>
																</div>
															</div>
														))}
												</div>
											)}
											{/* </div> */}
										</div>

										<div className="col-span-3 lg:col-span-3 flex items-end justify-end  ">
											${inv.TotalInvPrice ? inv.TotalInvPrice : inv.SubPrice}
											{Invoices.length > 1 && <MdDelete onClick={() => HandelRemoveInvoice(i, Invoices, setInvoices, dispatch)} className="text-[#000] text-[24px]" style={{ cursor: 'pointer' }} />}
										</div>
									</div>
								))}
						</div>
						<div className="col-span-3 lg:col-span-1">
							<div className="  border rounded-md px-4 sm:px-8 lg:px-6 xl:px-8 pt-7 pb-4 mb-7">
								<h4 className="font-AlbertSans font-bold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-PrimaryColor-0">
									Order Summary
									<div className="text-[12px]"> {Invoices.length} item </div>
								</h4>

								<h4 className="font-AlbertSans font-semibold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0  before:h-[2px] before:bg-PrimaryColor-0">
									Subtotal<span className="text-[12px]">(USD)</span> <span className="mx-6">${subtotal} </span>
								</h4>

								<div className="flex flex-col gap-4 pr-8  border-[#94c933] p-2 text-left" style={{ fontSize: '16px', fontWeight: 'bolder' }}>
									<button
										onClick={() => setCheckOutModalShow(true)}
										className="font-AlbertSans flex gap-2 items-center bg-JZPrimaryColor-0 p-6 w-full h-[28px] rounded-md justify-center z-10 relative before:absolute before:top-0 before:right-0 before:scale-0 before:-z-10 before:w-full before:h-full before:bg-SecondaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:scale-100 hover:text-white"
									>
										Ready to Pay
										<FaArrowRight />
									</button>
									<HandleClearInvButton setInvoices={setInvoices} />
								</div>
							</div>
							<div className="bg-[#f3f4f8]  border rounded-md px-4 sm:px-8 lg:px-6 xl:px-8 pt-7 pb-4 mb-7">
								<h4 className="font-AlbertSans font-bold text-2xl text-HeadingColor-0 pb-3 mb-8 relative before:absolute before:bottom-0 before:left-0 before:w-7 before:h-[2px] before:bg-PrimaryColor-0">Add Service</h4>

								<ul className="mt-8">
									{SubService &&
										SubService.filter((sub) => !sub.OncePerOrder).map((sub, i) => (
											<li key={i}>
												<button
													onClick={() => HandelAddInvoice(sub, Invoices, setInvoices, dispatch)}
													className="w-full font-AlbertSans bg-SecondaryColor-0 text-center text-white transition-all duration-500 group gap-2 px-1 py-4 flex items-center justify-left rounded bg-HoverColor-0 mb-3 overflow-hidden z-[1] relative before:absolute before:top-0 before:right-0 before:w-0 before:-z-[1] before:h-full before:bg-PrimaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:w-full hover:before:left-0 hover:text-white"
												>
													{' '}
													<FaArrowLeft size={'20'} className="text-PrimaryColor-0 transition-all duration-500 group-hover:text-white" /> {sub.SubServiceTitle}{' '}
												</button>
											</li>
										))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default ServiceDetails;
