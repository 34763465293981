// Define a secret key for encryption/decryption
const secretKey = 'mySecretKeyUseastrong24erkeyforprod3456uction';
import CryptoJS from 'crypto-js';

// Encrypt the invoices
export const encrypt = (data) => {
	const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
	return encrypted;
};

// Decrypt the invoices
export const decrypt = (data) => {
	const bytes = CryptoJS.AES.decrypt(data, secretKey);
	const decrypted = bytes.toString(CryptoJS.enc.Utf8);
	return decrypted ? JSON.parse(decrypted) : [];
};
