import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { JWT } from './jwt';
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/userSlice';

export const useJWT = () => {
	const token = useSelector((state) => state.users.status === 'succeeded' && state.users.users.token.jwt);
	const [UserInfo, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const dispath = useDispatch();
	const logout = () => {
		const fetchLogin = () => {
			try {
				dispath(logOut());
				setUser(null);
			} catch (error) {
				console.console.error(error);
			}
		};
		fetchLogin();
	};
	useEffect(() => {
		const fetchJWT = async () => {
			try {
				const data = await JWT(token); // Pass token explicitly
				if (data) setUser(data);
			} catch (error) {
				console.error('Error fetching JWT:', error);
			} finally {
				setLoading(false);
			}
		};

		if (token) fetchJWT();
	}, [token]);
	return { UserInfo, loading, logout };
};
