import { FaArrowRightLong } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { CustomerLogin } from '../../../data/userController';
import { fetchUsers } from '../../../redux/userSlice';

const Login = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [LoginUser, setLoginUser] = useState({
		Email: 'pierre.bornibiouc@gmail.com',
		Password: 'admin',
	});
	const [Msg, setMsg] = useState([]);

	const HandelLogin = async () => {
		// const login = await CustomerLogin(LoginUser, dispatch);
		dispatch(fetchUsers(LoginUser));
		const fetchLogin = () => {
			try {
				setMsg('login');
				const queryParams = new URLSearchParams(location.search);
				const redirectTo = queryParams.get('j');
				if (redirectTo) {
					navigate('/' + redirectTo); // Navigate to the `j` parameter's value
				} else {
					console.error('No `j` parameter found in the URL');
				}
			} catch (error) {
				console.console.error(error);
			}
		};
		fetchLogin();
	};

	return (
		<div className="mr-2">
			<h2 className="font-AlbertSans font-bold text-3xl md:text-[40px] mb-11">Login</h2>
			<div className="flex flex-col md:flex-col gap-7">
				<input
					type="email"
					name="email"
					id="email"
					placeholder="Your E-Mail*"
					required
					className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
					onChange={(e) => setLoginUser({ ...LoginUser, Email: e.target.value })}
					onKeyDown={() => HandelLogin()}
					value={LoginUser.Email}
				/>
				<input
					type="password"
					name="password"
					id="password"
					placeholder="Your Password"
					className="border border-BorderColor2-0 rounded py-2 px-6 outline-none h-[56px] w-full"
					onChange={(e) => setLoginUser({ ...LoginUser, Password: e.target.value })}
					onKeyDown={() => HandelLogin()}
					value={LoginUser.Password}
				/>
			</div>

			<div className="flex flex-row gap-24">
				<div className="inline-block">
					<label htmlFor="rememberme" className="flex items-center space-x-2">
						<input type="checkbox" id="rememberme" className="form-checkbox" checked disabled />
						<span>Remember me for auto-login</span>
					</label>
					<button type="submit" className="primary-btn2 hover:bg-JzSecondaryColor-0 mt-2" onClick={() => HandelLogin()}>
						Login
						<FaArrowRightLong size={'20'} />
					</button>
				</div>
			</div>
			<div style={{ color: '#f00', fontWeight: 'bolder' }}>{Msg}</div>
		</div>
	);
};

export default Login;
