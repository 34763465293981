import { configureStore, createAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage
import { combineReducers } from 'redux';
import dataReducer from './dataSlice';
import usersReducer from './userSlice';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

// Define a reset action
const resetStore = createAction('RESET_STORE');

const persistConfig = {
	key: 'root',
	storage,
};

const rootReducer = combineReducers({
	data: dataReducer,
	users: usersReducer,
});

const persistedReducer = persistReducer(persistConfig, (state, action) => {
	if (action.type === resetStore.type) {
		state = undefined; // Reset state to initial values
	}
	return rootReducer(state, action);
});

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);

// Remove persisted store and reset Redux state after 10 seconds
// setTimeout(() => {
// 	persistor.purge();
// 	store.dispatch(resetStore());
// 	console.log('Persisted store has been removed and Redux state has been reset.');
// }, 10000);
