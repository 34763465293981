import { FaArrowRightLong } from 'react-icons/fa6';
import BreadCrumb from '../../../Shared/BreadCrumb/BreadCrumb';
import Login from './Login';
import { uploadUrl } from '../../../Components/urls';
import { UseAbout } from '../../../Components/useAbout';
import { useState, useEffect } from 'react';
import Register from './Register';
import { useJWT } from '../../../data/useUserInfo';
import { Customers } from './Customer';
import { useSession } from '../../../Components/useSession';
import { Link } from 'react-router-dom';
import { FiPhoneCall } from 'react-icons/fi';
import shape from '/images/appointment-shape.png';
import shape2 from '/images/appointment-shape2.png';
import { useSelector } from 'react-redux';

const CustomerLogin = () => {
	const { UserInfo } = useJWT();
	const { SessionExpired } = useSession();
	const AboutData = UseAbout();
	const [IsLogin, setIsLogin] = useState(true);
	const { users, status, error } = useSelector((state) => state.users);

	if (status === 'loading') {
		return (
			<div className="flex justify-center items-center h-screen">
				<p>Loading...</p>
			</div>
		);
	}

	if (status === 'failed') {
		return <div>Error: {error}</div>;
	}

	return (
		<>
			<BreadCrumb
				breadCrumbPhoto={uploadUrl + AboutData.AboutSectionHeadPhoto}
				breadCrumbTitle={'Customer Profile'}
				breadCrumbIcon={<FaArrowRightLong />}
				breadCrumbLink={'Customer Profile'}
			/>
			{UserInfo && SessionExpired > 0 ? (
				<Customers UserInfo={UserInfo} />
			) : status !== 'loading' ? (
				<section
					className="py-28 relative 
              bg-[linear-gradient(to_bottom,_#60422A_40%,_#FFFFFF_32%)] 
              sm:bg-[linear-gradient(to_bottom,_#60422A_41.5%,_#FFFFFF_32%)]
               md:bg-[linear-gradient(to_top,_#FFFFFF_52.5%,_#60422A_48.5%)] 
               lg:bg-[linear-gradient(to_right,_#60422A_50%,_#FFFFFF_50%)]
                  overflow-hidden"
				>
					<img src={shape} className="absolute -left-2 -top-2 z-20" />
					<div className="Container">
						<div className="grid grid-cols-1 lg:grid-cols-2 gap-40 md:gap-40 lg:gap-28 items-center">
							<div className="relative text-center">
								<img src={shape2} className="absolute right-0 -top-20 animate-zoomInOut hidden 2xl:block" />
								<div className="w-[110px] h-[110px] text-white relative rounded-full flex justify-center items-center m-auto before:absolute before:top-0 before:left-0 before:w-full before:h-full before:border before:text-white before:border-dashed before:border-white before:rounded-full before:animate-rotational">
									<FiPhoneCall size={'50'} />
								</div>
								<h1 className="font-AlbertSans font-bold text-[22px] leading-8 sm:text-[38px] sm:leading-[48px] md:text-[44px] md:leading-[54px] lg:text-[32px] lg:leading-[42px] xl:text-[40px] xl:leading-[50px] 2xl:text-[46px] 2xl:leading-[56px] text-white mt-9 mb-11">
									Dial for a Consultation
									<br />!
								</h1>
								<div className="inline-block">
									<Link to={'/about'} className="flex justify-center items-center">
										<button className="primary-btn2 !border-white !border">
											Contact Now
											<FaArrowRightLong size={'20'} />
										</button>
									</Link>
								</div>
							</div>

							<div className="relative text-right">
								{IsLogin ? <Login /> : <Register />}
								<hr className="mt-4" />
								<button
									type="submit"
									className="primary-btn6  bg-JzSecondaryColor-0 text-white  p-4 m-4"
									onClick={() => setIsLogin(!IsLogin)}
									style={{ fontWeight: 'bolder', fontSize: '14px', borderRadius: '5px' }}
								>
									{IsLogin ? 'Create new account' : 'Already have an Account?'}
								</button>
							</div>
						</div>
					</div>
				</section>
			) : status === 'loading' ? (
				<div className="flex justify-center items-center h-screen">
					<p>Loading...</p>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default CustomerLogin;
