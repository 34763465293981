import { useDispatch } from 'react-redux';
import { ClearInv } from '../../../../redux/dataSlice';
import { FaArrowRight } from 'react-icons/fa6';

export const HandleClearInvButton = ({ setInvoices }) => {
	const dispatch = useDispatch();

	const handleClearInv = () => {
		try {
			dispatch(ClearInv());
			setInvoices([]); // Uncomment if you need to clear any other state
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<button
			onClick={() => handleClearInv()}
			className="font-AlbertSans text-white flex gap-2 items-center bg-JzSecondaryColor-0 p-6 w-full h-[28px] rounded-md justify-center z-10 relative before:absolute before:top-0 before:right-0 before:scale-0 before:-z-10 before:w-full before:h-full before:bg-SecondaryColor-0 before:rounded before:transition-all before:duration-500 hover:before:scale-100 hover:text-white"
		>
			Remove all
			<FaArrowRight />
		</button>
	);
};
